import mock from '../mock'

const utilities = {
  status: [
    {
      name: 'Active',
      id: 1,
      color: 'primary'
    },
    {
      name: 'In Active',
      id: 2,
      color: 'danger'
    }
  ],
  clientAccessStatus: [
    {
      name: 'Enabled',
      id: true,
      color: 'primary'
    },
    {
      name: 'Not Enabled',
      id: false,
      color: 'danger'
    }
  ]

}

export  const documentPlaces = [
  { id: 1, name: 'In', color: 'priamry' },
  { id: 2, name: 'Out', color: 'danger' }
]


mock.onPost('/api/clients/status/dropdown').reply(200, utilities.status)

mock.onPost('/api/clients/documentstatus/dropdown').reply(200, documentPlaces)

mock.onPost('/api/clients/clientaccess/dropdown').reply(200, utilities.clientAccessStatus)
