import { HiOutlineFolderOpen } from "react-icons/hi"

export default [

  {
    id: 'filemanager',
    title: 'File Manager',
    icon: <HiOutlineFolderOpen />,
    navLink: '/filemanager',
    resource: 'filemanager'
  }

]