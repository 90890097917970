// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/configs/axios/axiosConfig'

export const revenueTrends = createAsyncThunk('appDashboard/revenueTrends', async (data, { }) => {
    const response = await axios.post('dashboard/revenueTrends', data)
    return response.data.revenueTrends
})

export const revenueByCategories = createAsyncThunk('appDashboard/revenueByCategories', async (data, { }) => {
    const response = await axios.post('dashboard/revenueByCategories', data)
    return response.data.revenueByCategories
})


export const appDashboardSlice = createSlice({
    name: 'appInvoice',
    initialState: {
        revenueTrends: {},
        revenueByCategories :[]
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(revenueTrends.fulfilled, (state, action) => {
            state.revenueTrends = action.payload
        })
        builder.addCase(revenueByCategories.fulfilled, (state, action) => {
            state.revenueByCategories = action.payload
        })
        
    }
})

export default appDashboardSlice.reducer
