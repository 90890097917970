import { HiOutlineLockClosed } from "react-icons/hi"

export default [
  {
    id: 'passwords',
    title: 'Passwords',
    icon: <HiOutlineLockClosed />,
    resource: 'passwords',
    navLink: '/passwords'
  }
]