// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from '@src/configs/axios/axiosConfig'
import { getUserData } from '@utils'
import { activeOrgDetails } from '@src/views/apps/organization/store/index.js'
import { store } from '@store/store'

export const getOrganization = createAsyncThunk('appBillingOrganization/getData', async id => {
  const user = getUserData()
  const response = await axios.post(`/organizations/get`, { id, userId: user.id })
  await store.dispatch(activeOrgDetails(response.data.organizations))
  localStorage.setItem('activeOrganization', JSON.stringify(response.data.organizations))
  return {
    data: response.data.organizations
  }
})

export const getactiveorg = createAsyncThunk('appBillingOrganization/getactiveorg', async id => {
  const response = await axios.post(`/organizations/getactiveorg`, { id })
  localStorage.setItem('activeOrganization', JSON.stringify(response.data.organizations))
  await store.dispatch(activeOrgDetails(response.data.organizations))
  return {
    data: response.data.organizations
  }
})

export const createOrganization = createAsyncThunk('appBillingOrganization/createOrganization', async (organization, { rejectWithValue }) => {
  try {
    const response = await axios.post('/organizations/create', organization)
    return response.data.organizations
  } catch (ex) {
    console.log(ex.response)
    return rejectWithValue(ex.response)
  }
})


export const updateOrganization = createAsyncThunk('appBillingOrganization/addOrgPreference', async (data, { }) => {

  const res = await axios.post(`/organizations/update`, data)
  return res.data.organizations

})

export const paymentStaus = createAsyncThunk('appBillingOrganization/paymentStaus', async (flag, { }) => {
  return flag
})

export const geoLocation = createAsyncThunk('appBillingOrganization/geoLocation', async (coords, { }) => {
  return coords
})


export const appBillingOrganizationSlice = createSlice({
  name: 'appBillingOrganization',
  initialState: {
    organizationData: null,
    paymentStausFlag: false,
    geoCordinate: null,
    organizationCreateError : {},
    organizationId : null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getOrganization.fulfilled, (state, action) => {
        state.organizationData = action.payload.data
      })
      .addCase(createOrganization.fulfilled, (state, action) => {
        state.organizationId = action.payload.id
      })
      .addCase(createOrganization.rejected, (state, action) => {
        state.organizationCreateError = action.payload.data.errors
      })
      .addCase(getactiveorg.fulfilled, (state, action) => {
        state.organizationData = action.payload.data
      })
      .addCase(paymentStaus.fulfilled, (state, action) => {
        state.paymentStausFlag = action.payload
      })
      .addCase(geoLocation.fulfilled, (state, action) => {
        state.geoCordinate = action.payload
      })
  }
})

export default appBillingOrganizationSlice.reducer
