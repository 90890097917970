// ** React Imports
import { Link } from 'react-router-dom'
import { Fragment, useEffect, useState } from 'react'

// ** Third Party Components
import * as Icon from 'react-feather'
import classnames from 'classnames'
import * as HiIcon from 'react-icons/hi'
import * as Hi2Icon from 'react-icons/hi2'

// ** Custom Component
import Autocomplete from '@components/autocomplete'

// ** Reactstrap Imports
import {
  NavItem,
  NavLink,
  UncontrolledTooltip,
  Popover,
  PopoverHeader,
  PopoverBody,
  Button,
  Row,
  Col,
  Badge
} from 'reactstrap'

// ** Store & Actions
import { endTimer } from '@src/views/apps/task/store/index.js'
import { useDispatch, useSelector } from 'react-redux'
import { getBookmarks, updateBookmarked, handleSearchQuery } from '@store/navbar'
import axios from '@src/configs/axios/axiosConfig'
import moment from 'moment'
import { orgUserId } from '@src/helper/sassHelper'
const userId = orgUserId()
const NavbarBookmarks = props => {
  // ** Props
  const { setMenuVisibility } = props

  // ** State
  const [popoverOpen, setPopoverOpen] = useState(false)
  const [value, setValue] = useState('')
  const [openSearch, setOpenSearch] = useState(false)
  const [timerFlag, setTimerFlag] = useState(false)
  const [hourlyRate, setHourlyRate] = useState(0)
  const [currentTask, setCurrentTask] = useState(null)
  const [chatNotificationCount, setChatNotificationCount] = useState(0)

  // ** Store Vars
  const dispatch = useDispatch()
  const store = useSelector(state => state.navbar)
  const taskstore = useSelector(state => state.task)
  const chatstore = useSelector(state => state.chat)

  const getEmployeeDet = () => {
    axios.post('/organizationusers/getjobinfo', { id: userId })
      .then((res) => {
        setHourlyRate(res.data.organizationusers.hourlyrate || 0)
      }).catch((err) => {
        console.log(err)
      })
  }

  // ** ComponentDidMount
  useEffect(() => {
    dispatch(getBookmarks())
    if (userId !== null) {
      getEmployeeDet()
    }
  }, [])

  useEffect(() => {
    setChatNotificationCount(chatstore.notificationCount)
  }, [chatstore.notificationCount])

  // ** Loops through Bookmarks Array to return Bookmarks
  const renderBookmarks = () => {
    if (store.bookmarks.length) {
      return store.bookmarks
        .map(item => {
          const IconTag = (HiIcon[item.icon] ?? Hi2Icon[item.icon]) ?? Icon[item.icon]
          return (
            <NavItem key={item.target} className='d-none d-lg-block'>
              <NavLink tag={Link} to={item.link} id={item.target}>
                {(item.id === 4 && chatNotificationCount > 0) &&
                  <div style={{ position: 'absolute', bottom: '14px', right: '-4px' }}>
                    <Badge color='danger' className='font-small-1'>{chatNotificationCount}</Badge>
                  </div>
                }
                <IconTag className='ficon' />
                <UncontrolledTooltip target={item.target}>{item.title}</UncontrolledTooltip>
              </NavLink>
            </NavItem>
          )
        })
        .slice(0, 10)
    } else {
      return null
    }
  }

  // ** If user has more than 10 bookmarks then add the extra Bookmarks to a dropdown
  // const renderExtraBookmarksDropdown = () => {
  //   if (store.bookmarks.length && store.bookmarks.length >= 11) {
  //     return (
  //       <NavItem className='d-none d-lg-block'>
  //         <NavLink tag='span'>
  //           <UncontrolledDropdown>
  //             <DropdownToggle tag='span'>
  //               <Icon.ChevronDown className='ficon' />
  //             </DropdownToggle>
  //             <DropdownMenu end>
  //               {store.bookmarks
  //                 .map(item => {
  //                   const IconTag = Icon[item.icon]
  //                   return (
  //                     <DropdownItem tag={Link} to={item.link} key={item.id}>
  //                       <IconTag className='me-50' size={14} />
  //                       <span className='align-middle'>{item.title}</span>
  //                     </DropdownItem>
  //                   )
  //                 })
  //                 .slice(10)}
  //             </DropdownMenu>
  //           </UncontrolledDropdown>
  //         </NavLink>
  //       </NavItem>
  //     )
  //   } else {
  //     return null
  //   }
  // }

  // ** Removes query in store
  const handleClearQueryInStore = () => dispatch(handleSearchQuery(''))

  // ** Loops through Bookmarks Array to return Bookmarks
  const onKeyDown = e => {
    if (e.keyCode === 27 || e.keyCode === 13) {
      setTimeout(() => {
        setOpenSearch(false)
        handleClearQueryInStore()
      }, 1)
    }
  }

  // ** Function to toggle Bookmarks
  const handleBookmarkUpdate = id => dispatch(updateBookmarked(id))

  // ** Function to handle Bookmarks visibility
  // const handleBookmarkVisibility = () => {
  //   setOpenSearch(!openSearch)
  //   setValue('')
  //   handleClearQueryInStore()
  // }

  // ** Function to handle Input change
  const handleInputChange = e => {
    setValue(e.target.value)
    dispatch(handleSearchQuery(e.target.value))
  }

  // ** Function to handle external Input click
  const handleExternalClick = () => {
    if (openSearch === true) {
      setOpenSearch(false)
      handleClearQueryInStore()
    }
  }

  // ** Function to clear input value
  const handleClearInput = setUserInput => {
    if (!openSearch) {
      setUserInput('')
      handleClearQueryInStore()
    }
  }


  useEffect(() => {
    let temp = JSON.parse(localStorage.getItem('currentTask'))
    if (!temp) {
      localStorage.removeItem('currentTask')
      temp = null
    } else {
      const today = moment().startOf('d').valueOf()
      if (temp?.starttime < today) {
        localStorage.removeItem('currentTask')
        temp = null
      }
    }
    if (taskstore.selectedTaskTimer !== null) {
      setTimerFlag(true)
      setCurrentTask(taskstore.selectedTaskTimer)
    } else if (temp !== null) {
      setTimerFlag(true)
      setCurrentTask(temp)
    } else if (taskstore.selectedTaskTimer === null) {
      setTimerFlag(false)
      setPopoverOpen(false)
      setCurrentTask(null)
      localStorage.removeItem('currentTask')
    } else {
      setTimerFlag(false)
      setPopoverOpen(false)
    }
  }, [taskstore.selectedTaskTimer])

  const stopTaskTimer = async () => {
    const temp = JSON.parse(localStorage.getItem('currentTask'))
    if (temp !== undefined || temp !== null) {
      const end = moment()
      const start = moment(temp.starttime, 'x').toDate()
      const end_timestamp = moment().valueOf()
      const duration = moment.duration(end.diff(start))
      const hours = duration.asHours()
      const amount = hourlyRate * hours
      const data = {
        updatedBy: userId,
        id: temp.id,
        taskId: temp.taskid,
        totalhours: hours,
        amount,
        hourlyRate,
        userId,
        endTime: end_timestamp
      }
      await dispatch(endTimer(data))
      await localStorage.removeItem('currentTask')
      setPopoverOpen(false)
      await setTimerFlag(false)
    }
  }

  return (
    <Fragment>
      <ul className='navbar-nav d-xl-none'>
        <NavItem className='mobile-menu me-auto'>
          <NavLink className='nav-menu-main menu-toggle hidden-xs is-active' onClick={() => setMenuVisibility(true)}>
            <Icon.Menu className='ficon' />
          </NavLink>
        </NavItem>
      </ul>
      <ul className='nav navbar-nav bookmark-icons'>
        {renderBookmarks()}
        <NavItem className='hidden-xs is-active d-xl-none d-md-none text-body' tag={Link} to={`/todo`}>
          <Icon.CheckSquare className='me-50' size={22} />
        </NavItem>
        <NavItem className='hidden-xs is-active d-xl-none d-md-none text-body' tag={Link} to={`/apps/chat`}>
          <Icon.MessageSquare className='me-50' size={22} />
        </NavItem>
        <NavItem className='hidden-xs is-active d-xl-none d-md-none  text-body' tag={Link} to={`/apps/calendar`}>
          <Icon.Calendar className='me-50' size={22} />
        </NavItem>
        {timerFlag &&
          <Fragment>
            <NavItem id='popover_timer' onClick={() => setPopoverOpen(!popoverOpen)}>
              <Icon.Clock className='ficon text-danger cursor-pointer' />
            </NavItem>
          </Fragment>
        }
        {popoverOpen &&
          <Popover trigger='legacy' target='popover_timer' placement='right' isOpen={popoverOpen}
            toggle={() => setPopoverOpen(!popoverOpen)}>
            <PopoverHeader>Task Timer</PopoverHeader>
            <PopoverBody>
              <Col className='d-flex'>
                <Button color='danger' className='ms-1' onClick={() => stopTaskTimer()} size='md' >
                  Stop Timer
                </Button>
                <Button size='md' className='ms-1' color='primary' onClick={() => setPopoverOpen(!popoverOpen)} tag={Link} to={`/task/view/${currentTask?.taskid}`}>
                  Goto Task
                </Button>
              </Col>
            </PopoverBody>
          </Popover>
        }

        {/* {renderExtraBookmarksDropdown()} */}
        <NavItem className='nav-item d-none d-lg-block'>
          {/* <NavLink className='bookmark-star' onClick={handleBookmarkVisibility}>
            <Icon.Star className='ficon text-warning' />
          </NavLink> */}
          <div className={classnames('bookmark-input search-input', { show: openSearch })}>
            <div className='bookmark-input-icon'>
              <Icon.Search size={14} />
            </div>
            {openSearch && store.suggestions.length ? (
              <Autocomplete
                wrapperClass={classnames('search-list search-list-bookmark', {
                  show: openSearch
                })}
                className='form-control'
                suggestions={!value.length ? store.bookmarks : store.suggestions}
                filterKey='title'
                autoFocus={true}
                defaultSuggestions
                suggestionLimit={!value.length ? store.bookmarks.length : 6}
                placeholder='Search...'
                externalClick={handleExternalClick}
                clearInput={(userInput, setUserInput) => handleClearInput(setUserInput)}
                onKeyDown={onKeyDown}
                value={value}
                onChange={handleInputChange}
                customRender={(
                  item,
                  i,
                  filteredData,
                  activeSuggestion,
                  onSuggestionItemClick,
                  onSuggestionItemHover
                ) => {
                  const IconTag = Icon[item.icon ? item.icon : 'X']
                  return (
                    <li
                      key={i}
                      onMouseEnter={() => onSuggestionItemHover(filteredData.indexOf(item))}
                      className={classnames('suggestion-item d-flex align-items-center justify-content-between', {
                        active: filteredData.indexOf(item) === activeSuggestion
                      })}
                    >
                      <Link
                        to={item.link}
                        className='d-flex align-items-center justify-content-between p-0'
                        onClick={() => {
                          setOpenSearch(false)
                          handleClearQueryInStore()
                        }}
                        style={{
                          width: 'calc(90%)'
                        }}
                      >
                        <div className='d-flex justify-content-start align-items-center overflow-hidden'>
                          <IconTag size={17.5} className='me-75' />
                          <span className='text-truncate'>{item.title}</span>
                        </div>
                      </Link>
                      <Icon.Star
                        size={17.5}
                        className={classnames('bookmark-icon float-end', {
                          'text-warning': item.isBookmarked
                        })}
                        onClick={() => handleBookmarkUpdate(item.id)}
                      />
                    </li>
                  )
                }}
              />
            ) : null}
          </div>
        </NavItem>
      </ul>
    </Fragment>
  )
}

export default NavbarBookmarks
