import mock from '../mock'

export const searchArr = [
  {
    groupTitle: 'Pages',
    searchLimit: 4,
    data: [
      {
        id: 1,
        target: 'analyticsDash',
        isBookmarked: false,
        title: 'Analytics Dashboard',
        icon: 'Home',
        link: '/dashboard/analytics'
      },
      {
        id: 2,
        target: 'eCommerceDash',
        isBookmarked: true,
        title: 'Dashboard',
        icon: 'Home',
        link: '/dashboard'
      },
      {
        id: 3,
        target: 'chat',
        isBookmarked: true,
        title: 'Chat',
        icon: 'HiOutlineChat',
        link: '/apps/chat'
      },
      {
        id: 4,
        target: 'calendar',
        isBookmarked: true,
        title: 'Calendar',
        icon: 'HiOutlineCalendar',
        link: '/apps/calendar'
      },
      {
        id: 5,
        target: 'feeds',
        isBookmarked: true,
        title: 'Feeds',
        icon: 'HiOutlineDocumentText',
        link: '/feeds'
      },
      {
        id: 6,
        target: 'todo',
        isBookmarked: true,
        title: 'Todo',
        icon: 'HiOutlineDocumentCheck',
        link: '/todo'
      }
    ]
  },
  {
    groupTitle: 'Contacts',
    searchLimit: 4,
    data: [
      {
        title: 'Mia Davis',
        email: 'miadavis@teleworm.us',
        img: require('@src/assets/images/portrait/small/avatar-s-8.jpg').default,
        date: '01/03/2020'
      },
      {
        title: 'Norris Carrière',
        email: 'NorrisCarriere@rhyta.com',
        img: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
        date: '07/03/2020'
      },
      {
        title: 'Charlotte Gordon',
        email: 'CharlotteGordon@jourrapide.com',
        img: require('@src/assets/images/portrait/small/avatar-s-26.jpg').default,
        date: '14/03/2020'
      },
      {
        title: 'Robert Nash',
        email: 'RobertNash@dayrep.com',
        img: require('@src/assets/images/portrait/small/avatar-s-25.jpg').default,
        date: '21/03/2020'
      }
    ]
  }
]

// GET Search Data
mock.onGet('/api/main-search/data').reply(() => {
  return [200, { searchArr }]
})

// GET Search Data & Bookmarks
mock.onGet('/api/bookmarks/data').reply(() => {
  const bookmarks = searchArr[0].data.filter(item => item.isBookmarked)
  const suggestions = searchArr[0].data
  return [200, { suggestions, bookmarks }]
})

// POST Update isBookmarked
mock.onPost('/api/bookmarks/update').reply(config => {
  const { id } = JSON.parse(config.data)

  const obj = searchArr[0].data.find(item => item.id === id)

  Object.assign(obj, { isBookmarked: !obj.isBookmarked })

  return [200]
})
