// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as FileSaver from "file-saver"
import * as XLSX from "xlsx"
// ** Axios Imports
import axios from '@src/configs/axios/axiosConfig'
import moment from 'moment'

export const getData = createAsyncThunk('appAttendance/getData', async params => {
  const response = await axios.post('/employeeattendances/list', params)
  return {
    params,
    data: response.data.employeeattendances
  }
})

export const getDailyList = createAsyncThunk('appAttendance/getDailyList', async params => {
  const response = await axios.post('/employeeattendances/dailylist', params)
  return {
    params,
    data: response.data.employeeattendances
  }
})

const msToTime = (ms) => {

  let seconds = ms / 1000
  const hours = parseInt(seconds / 3600)
  seconds = seconds % 3600
  const minutes = parseInt(seconds / 60)
  seconds = seconds % 60
  return `${hours} Hrs : ${minutes} Mins`
}

const hoursFormat = (totalhours) => {

  const decimalTimeString = totalhours
  let decimalTime = parseFloat(decimalTimeString)
  decimalTime = decimalTime * 60 * 60
  let hours = Math.floor((decimalTime / (60 * 60)))
  decimalTime = decimalTime - (hours * 60 * 60)
  let minutes = Math.floor((decimalTime / 60))

  if (hours < 10) {
    hours = `0${hours}`
  }
  if (minutes < 10) {
    minutes = `0${minutes}`
  }

  return (`${isNaN(hours) ? '00' : hours}Hrs : ${isNaN(minutes) ? '00' : minutes}Mins`)

}

const findHours = (ms) => {

  const seconds = ms / 1000
  const hours = parseInt(seconds / 3600)
  return hours

}

export const exportDailyList = createAsyncThunk('appAttendance/exportDailyList', async params => {
  const response = await axios.post('/employeeattendances/dailylist', params)

  const result = await response.data.employeeattendances.map((obj) => {

    const data = {}
    data['name'] = obj.name
    data['clockin'] = obj.punchin ? moment(obj.punchin, 'x').format('h:m a') : ''
    data['clockout'] = obj.punchout ? moment(obj.punchout, 'x').format('h:m a') : ''
    data['runningtime'] = obj.totalhours
    data['location'] = obj.locationname
    data['punchoutlocation'] = obj.punchoutlocation

    return data
  })

  const ws = await XLSX.utils.json_to_sheet(result, { origin: 'A2', skipHeader: true })
  const Heading = [['Team Members', "Clock In", 'Clock Out', 'Running Time', 'Punch In Location', 'Punch Out Location']]
  XLSX.utils.sheet_add_aoa(ws, Heading)
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
  const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" })
  FileSaver.saveAs(data, 'Today_Attendace_Monnthly_Summary_Report.xlsx')
})

export const getMonthlySummary = createAsyncThunk('appAttendance/getMonthlySummary', async params => {
  const response = await axios.post('/employeeattendances/monthlysummary', params)
  return {
    params,
    data: response.data.employeeattendances
  }
})

export const getMonthlySummaryExport = createAsyncThunk('appAttendance/getMonthlySummaryExport', async params => {
  const response = await axios.post('/employeeattendances/monthlysummary', params)
  const result = await response.data.employeeattendances.map((obj) => {

    const data = {}
    data['name'] = obj.name
    data['noOfDays'] = obj.noOfDays
    data['totalHous'] = msToTime(obj.totalHous)
    data['totalbreak'] = hoursFormat(obj.totalbreak)
    data['averagehours'] = obj.noOfDays > 0 ? parseFloat(findHours(obj.totalHous) / parseFloat(obj.noOfDays)).toFixed(2) : 0

    return data
  })

  const ws = await XLSX.utils.json_to_sheet(result, { origin: 'A2', skipHeader: true })
  const Heading = [['Team Members', "No of Day's", 'Total Hours', 'Total Break', 'Average Hours per day']]
  XLSX.utils.sheet_add_aoa(ws, Heading)
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
  const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" })
  FileSaver.saveAs(data, 'Attendace_Monnthly_Summary_Report.xlsx')

})

export const getSummaries = createAsyncThunk('appAttendance/getSummaries', async params => {
  const response = await axios.post('/employeeattendances/getsummaries', params)
  return {
    params,
    data: response.data.employeeattendances.employeeattendances,
    totalPages: response.data.employeeattendances.total
  }
})

export const appAttendanceSlice = createSlice({
  name: 'appAttendance',
  initialState: {
    data: [],
    monthlySummaries: [],
    total: 1,
    params: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getSummaries.fulfilled, (state, action) => {
        state.monthlySummaries = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getDailyList.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
      })
      .addCase(getMonthlySummary.fulfilled, (state, action) => {
        state.monthlySummaries = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
  }
})

export default appAttendanceSlice.reducer
