/* eslint-disable comma-dangle */
/* eslint-disable multiline-ternary */
/* eslint-disable semi */
// ** React Imports
import { Navigate } from "react-router-dom";
import { Suspense, useContext } from "react";
import Cookies from "js-cookie";
// ** Context Imports
import { AbilityContext } from "@src/utility/context/Can";

const PrivateRoute = ({ children, route }) => {
  // ** Hooks & Vars
  const ability = useContext(AbilityContext);
  const user = Cookies.get("userData")
    ? JSON.parse(Cookies.get("userData"))
    : null;
  const tempArr = [
    "reports",
    "attendance",
    "feeds",
    "leave",
    "passwords",
    "chat",
    "dashboard",
    "filemanager",
    "profile",
    "time-sheet",
    "coming-soon",
  ];
  if (route) {
    let action = null;
    let resource = null;
    let restrictedRoute = false;

    action = route?.action;
    resource = route?.resource;
    restrictedRoute = route?.meta?.restricted ?? false;

    if (!user) {
      return <Navigate to="/login" />;
    }
    if (user && restrictedRoute) {
      return <Navigate to="/" />;
    }
    if (
      user &&
      !ability.can(action || "read", resource) &&
      tempArr.indexOf(resource) === -1
    ) {
      return <Navigate to="/misc/not-authorized" />;
    }
    if (!user && !ability.can(action || "read", resource)) {
      return <Navigate to="/misc/not-authorized" replace />;
    }
  }

  return <Suspense fallback={null}>{children}</Suspense>;
};

export default PrivateRoute;
