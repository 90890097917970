import axios from '@src/configs/axios/axiosConfig'

export const indianFormattedPrice = (val) => {

  const value = String(val)

  let removedValue = ''
  let finalVal = ''
  if (value.includes('.')) {
    removedValue = value.slice(value.indexOf('.'))
    finalVal = value.replace(removedValue, '')
    finalVal = finalVal.toString()
  } else {
    finalVal = value.toString()
  }
  let lastThree = finalVal.substring(finalVal.length - 3)
  const otherNumbers = finalVal.substring(0, finalVal.length - 3)
  if (otherNumbers !== '') lastThree = `,${lastThree}`
  const res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree
  return res + (removedValue === '' ? '.00' : `.${removedValue.toString().split('.')[1]}`)
}

export const hoursFormat = (totalhours) => {

  const decimalTimeString = totalhours
  let decimalTime = parseFloat(decimalTimeString)
  decimalTime = decimalTime * 60 * 60
  let hours = Math.floor((decimalTime / (60 * 60)))
  decimalTime = decimalTime - (hours * 60 * 60)
  let minutes = Math.floor((decimalTime / 60))

  if (hours < 10) {
    hours = `0${hours}`
  }
  if (minutes < 10) {
    minutes = `0${minutes}`
  }

  return (`${isNaN(hours) ? '00' : hours}Hrs : ${isNaN(minutes) ? '00' : minutes}Mins`)

}

export const currencyConvert = async (base_currency, currencies) => {

  const res = await axios.get(
    `https://api.freecurrencyapi.com/v1/latest?apikey=fca_live_C8lWFFJJ2Q2soCa9lcN99U1dc8yl7A4gUoXNyjhj&base_currency=${base_currency}&currencies=${currencies}`)


  return res?.data?.data[currencies]
}