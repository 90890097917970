// ** React Imports
import { NavLink } from 'react-router-dom'

// ** Third Party Components
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Badge, Button } from 'reactstrap'
import { useEffect, useState } from 'react'

const HorizontalNavMenuLink = ({ item, isChild, setMenuOpen }) => {
  // ** Conditional Link Tag, if item has newTab or externalLink props use <a> tag else use NavLink
  const LinkTag = item.externalLink ? 'a' : NavLink
  const [notificationCount, setNotificationCount] = useState(0)
  // ** Hooks
  const { t } = useTranslation()

  const handleClick = () => {
    if (setMenuOpen) {
      setMenuOpen(false)
    }
  }

  useEffect(() => {
    setNotificationCount(7)
  }, [])

  return (
    <li
      onClick={handleClick}
      className={classnames('nav-item', {
        disabled: item.disabled
      })}
    >
      <LinkTag
        className={classnames('d-flex align-items-center', {
          'dropdown-item': isChild,
          'nav-link': !isChild
        })}
        target={item.newTab ? '_blank' : undefined}
        /*eslint-disable */
        {...(item.externalLink === true
          ? {
            href: item.navLink || '/'
          }
          : {
            to: item.navLink || '/',
            className: ({ isActive }) => {
              const commonClass = 'd-flex align-items-center px-50'
              if (isActive && !item.disabled && item.navLink !== '#') {
                if (isChild) {
                  return `${commonClass} dropdown-item active`
                } else {
                  return `${commonClass} nav-link active`
                }
              } else {
                if (isChild) {
                  return `${commonClass} dropdown-item`
                } else {
                  return `${commonClass} nav-link`
                }
              }
            }
          })}
      /*eslint-enable */
      >

        {item.icon}
        <span>{t(item.title)}

          {item.newTag && <Badge className={'ms-25'} color='light-success' style={{ fontSize: '9px' }}>New</Badge>}
          {item.badge ? (
            <Badge className='menu-notification-badge ms-auto' color={item.badge} pill>
              {notificationCount}
            </Badge>
          ) : null}
        </span>
      </LinkTag>
    </li>
  )
}

export default HorizontalNavMenuLink
