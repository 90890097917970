// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as FileSaver from "file-saver"
import * as XLSX from "xlsx"
// ** Axios Imports
import axios from '@src/configs/axios/axiosConfig'
import moment from 'moment'

export const taskTrends = createAsyncThunk('appDashboard/taskTrends', async (data, { }) => {
    const response = await axios.post('dashboard/taskTrends', data)
    return response.data.taskTrends
})

export const taskByServices = createAsyncThunk('appDashboard/taskByServices', async (data, { }) => {
    const response = await axios.post('dashboard/taskByServices', data)
    return response.data.taskByServices
})

export const activeTasks = createAsyncThunk('appDashboard/activeTasks', async (data, { }) => {
    const response = await axios.post('dashboard/activeTasks', data)
    return response.data.activeTasks
})

export const liveTasks = createAsyncThunk('appDashboard/liveTasks', async (data, { }) => {
    const response = await axios.post('dashboard/liveTasks', data)
    return response.data.liveTasks
})

export const taskCountbByStatus = createAsyncThunk('appDashboard/taskCountbByStatus', async (data, { }) => {
    const response = await axios.post('tasks/taskcountbystatus', data)
    return response.data.tasks
})
const statusOptions = ['', 'Pending', 'In progress', 'Completed', 'On Hold', 'Cancelled', 'Sent to Review', 'Request Changes']
export const exportTasks = createAsyncThunk('appTasks/exportTasks', async params => {
    const response = await axios.post('dashboard/activeTasks', params)

    const result = await response.data.activeTasks.tasks.map((obj) => {

        const data = {}
        data['service'] = obj.taskname
        data['uniqueidentity'] = obj.uniqueidentity
        data['client'] = obj.contactname
        data['deadline'] = obj.enddate.length === 13 ? moment(obj.enddate, 'x').format("MMM DD, YYYY") : moment.unix(obj.enddate).format('MMM DD, YYYY')
        data['completion'] = `${obj.taskstatus === '3' ? 100 : (obj.percentage ?? (obj.taskstatus === '3' ? 100 : 0))} %`
        data['taskstatus'] = statusOptions[obj.taskstatus]
        data['assignee'] = obj.users?.map((user) => { return parseInt(user.type) === 1 ? user.name : null })?.join('')

        return data
    })

    const ws = await XLSX.utils.json_to_sheet(result, { origin: 'A2', skipHeader: true })
    const Heading = [['Task Name', 'Task ID', 'Client Name', 'Deadline', 'Completion', 'Status', 'Assigned To']]
    XLSX.utils.sheet_add_aoa(ws, Heading)
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
    const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheetcharset=UTF-8" })
    FileSaver.saveAs(data, 'Task.xlsx')

})

export const appDashboardSlice = createSlice({
    name: 'appInvoice',
    initialState: {
        taskTrends: {},
        taskByServices: [],
        activeTasks: [],
        liveTasks: [],
        tasks: []
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(taskTrends.fulfilled, (state, action) => {
            state.taskTrends = action.payload
        })
        builder.addCase(taskByServices.fulfilled, (state, action) => {
            state.taskByServices = action.payload
        })
        builder.addCase(activeTasks.fulfilled, (state, action) => {
            state.activeTasks = action.payload
        })
        builder.addCase(liveTasks.fulfilled, (state, action) => {
            state.liveTasks = action.payload
        })
        builder.addCase(taskCountbByStatus.fulfilled, (state, action) => {
            state.tasks = action.payload
        })
    }
})

export default appDashboardSlice.reducer
