/* eslint-disable comma-dangle */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable semi */
// ** React Imports
import { lazy } from "react";
import { Navigate } from "react-router-dom";

const Chat = lazy(() => import("../../views/apps/chat"));
const Todo = lazy(() => import("../../views/apps/todo"));
const TodoMain = lazy(() => import("../../views/apps/todo/TodoMain"));
const EmailAlerts = lazy(() => import("../../views/apps/email"));
const Calendar = lazy(() => import("../../views/apps/calendar"));
const Feeds = lazy(() => import("../../views/apps/feeds"));
const Passwords = lazy(() => import("../../views/apps/passwords"));

const InvoiceAdd = lazy(() => import("../../views/apps/invoice/add"));
const InvoiceList = lazy(() => import("../../views/apps/invoice/list"));
const InvoiceEdit = lazy(() => import("../../views/apps/invoice/edit"));
const InvoiceClone = lazy(() => import("../../views/apps/invoice/clone"));
const InvoicePreview = lazy(() => import("../../views/apps/invoice/preview"));

const ReimbursementAdd = lazy(() =>
  import("../../views/apps/reimbursement/add")
);
const ReimbursementList = lazy(() =>
  import("../../views/apps/reimbursement/list")
);
const ReimbursementEdit = lazy(() =>
  import("../../views/apps/reimbursement/edit")
);
const ReimbursementClone = lazy(() =>
  import("../../views/apps/reimbursement/clone")
);
const ReimbursementPreview = lazy(() =>
  import("../../views/apps/reimbursement/preview")
);
const ComingSoon = lazy(() => import("../../views/apps/coming-soon"));
const FileManager = lazy(() => import("../../views/apps/file-manager"));

const ProposalAdd = lazy(() => import("../../views/apps/proposal/add"));
const ProposalList = lazy(() => import("../../views/apps/proposal/list"));
const ProposalEdit = lazy(() => import("../../views/apps/proposal/edit"));
const ProposalClone = lazy(() => import("../../views/apps/proposal/clone"));
const ProposalPreview = lazy(() => import("../../views/apps/proposal/preview"));

const EcommerceShop = lazy(() => import("../../views/apps/ecommerce/shop"));
const EcommerceDetail = lazy(() => import("../../views/apps/ecommerce/detail"));
const EcommerceWishlist = lazy(() =>
  import("../../views/apps/ecommerce/wishlist")
);
const EcommerceCheckout = lazy(() =>
  import("../../views/apps/ecommerce/checkout")
);

const ClientList = lazy(() => import("../../views/apps/client/list"));
const ClientView = lazy(() => import("../../views/apps/client/view"));
const ClientAdd = lazy(() => import("../../views/apps/client/add"));
const ClientEdit = lazy(() => import("../../views/apps/client/edit"));

const ConverClient = lazy(() =>
  import("../../views/apps/leads/convert-client")
);
const LeadList = lazy(() => import("../../views/apps/leads/list"));
const LeadView = lazy(() => import("../../views/apps/leads/view"));
const LeadAdd = lazy(() => import("../../views/apps/leads/add"));
const LeadEdit = lazy(() => import("../../views/apps/leads/edit"));

const Import = lazy(() => import("../../views/components/import"));

const TeamList = lazy(() => import("../../views/apps/team/list"));
const TeamView = lazy(() => import("../../views/apps/team/view"));
const TeamAdd = lazy(() => import("../../views/apps/team/add"));
const TeamEdit = lazy(() => import("../../views/apps/team/edit"));

const Profile = lazy(() => import("../../views/apps/profile"));

const AttendanceList = lazy(() => import("../../views/apps/attendance"));
const LeaveList = lazy(() => import("../../views/apps/leave/list"));
const EmployeeReimbursement = lazy(() =>
  import("../../views/apps/employee-reimbursement/list")
);
const TimeSheetList = lazy(() => import("../../views/apps/timesheet/list"));

const ServiceList = lazy(() => import("../../views/apps/service/list"));
const ServiceView = lazy(() => import("../../views/apps/service/view"));
const ServiceAdd = lazy(() => import("../../views/apps/service/add"));
const ServiceEdit = lazy(() => import("../../views/apps/service/edit"));

const CategoryList = lazy(() => import("../../views/apps/category/list"));

const TaskList = lazy(() => import("../../views/apps/task/list"));
const TaskSummary = lazy(() => import("../../views/apps/task/summary"));
const TaskView = lazy(() => import("../../views/apps/task/view"));
const TaskAdd = lazy(() => import("../../views/apps/task/add"));
const TaskEdit = lazy(() => import("../../views/apps/task/edit"));

const TaskWorkflow = lazy(() => import("../../views/apps/taskworkflow/view"));

const DSCList = lazy(() => import("../../views/apps/digital-signature/list"));
const DSCView = lazy(() => import("../../views/apps/digital-signature/view"));
const DSCAdd = lazy(() => import("../../views/apps/digital-signature/add"));
const DSCEdit = lazy(() => import("../../views/apps/digital-signature/edit"));

const Organization = lazy(() => import("../../views/apps/organization/add"));
const AppRoutes = [
  {
    element: <TodoMain />,
    path: "/todo",
    action: "read",
    resource: "task",
    meta: {
      appLayout: true,
      className: "todo-application",
    },
  },
  {
    element: <Todo />,
    path: "/todo/:filter",
    action: "read",
    resource: "task",
    meta: {
      appLayout: true,
      className: "todo-application",
    },
  },
  {
    element: <Todo />,
    path: "/todo/tag/:tag",
    action: "read",
    resource: "task",
    meta: {
      appLayout: true,
      className: "todo-application",
    },
  },
  {
    element: <EmailAlerts />,
    path: "/emailalerts",
    action: "read",
    resource: "setting",
    meta: {
      appLayout: true,
      className: "email-application",
    },
  },
  {
    element: <EmailAlerts />,
    path: "/emailalerts/:folder",
    action: "read",
    resource: "setting",
    meta: {
      appLayout: true,
      className: "email-application",
    },
  },
  {
    element: <EmailAlerts />,
    path: "/emailalerts/label/:label",
    action: "read",
    resource: "setting",
    meta: {
      appLayout: true,
      className: "email-application",
    },
  },
  {
    element: <EmailAlerts />,
    path: "/emailalerts/:filter",
    action: "read",
    resource: "setting",
  },
  {
    element: <Passwords />,
    path: "/passwords",
    action: "read",
    resource: "digital-signature",
  },
  {
    path: "/apps/chat",
    element: <Chat />,
    resource: "chat",
    meta: {
      appLayout: true,
      className: "chat-application",
    },
  },
  {
    element: <Calendar />,
    path: "/apps/calendar",
    action: "read",
    resource: "chat",
  },
  {
    element: <Feeds />,
    path: "/feeds",
    action: "read",
    resource: "chat",
  },
  {
    element: <InvoiceList />,
    path: "/invoice/list",
    action: "read",
    resource: "invoice",
  },
  {
    element: <InvoicePreview />,
    path: "/invoice/view/:id",
    action: "read",
    resource: "invoice",
  },
  {
    element: <InvoiceEdit />,
    path: "/invoice/edit/:id",
    action: "write",
    resource: "invoice",
  },
  {
    element: <InvoiceClone />,
    path: "/invoice/clone/:id",
    action: "write",
    resource: "invoice",
  },
  {
    element: <InvoiceAdd />,
    path: "/invoice/add",
    action: "write",
    resource: "invoice",
  },
  {
    element: <ReimbursementList />,
    path: "/reimbursement/list",
    action: "read",
    resource: "invoice",
  },
  {
    element: <ReimbursementPreview />,
    path: "/reimbursement/view/:id",
    action: "read",
    resource: "invoice",
  },
  {
    element: <ReimbursementEdit />,
    path: "/reimbursement/edit/:id",
    action: "write",
    resource: "invoice",
  },
  {
    element: <ReimbursementClone />,
    path: "/reimbursement/clone/:id",
    action: "write",
    resource: "invoice",
  },
  {
    element: <ReimbursementAdd />,
    path: "/reimbursement/add",
    action: "write",
    resource: "invoice",
  },
  {
    element: <ProposalList />,
    path: "/proposal/list",
    action: "read",
    resource: "invoice",
  },
  {
    element: <ProposalPreview />,
    path: "/proposal/view/:id",
    action: "read",
    resource: "invoice",
  },
  {
    element: <ProposalEdit />,
    path: "/proposal/edit/:id",
    action: "write",
    resource: "invoice",
  },
  {
    element: <ProposalClone />,
    path: "/proposal/clone/:id",
    action: "write",
    resource: "invoice",
  },
  {
    path: "/proposal/edit",
    element: <Navigate to="/proposal/edit/4987" />,
    action: "write",
    resource: "invoice",
  },
  {
    element: <ProposalAdd />,
    path: "/proposal/add",
    action: "write",
    resource: "invoice",
  },
  {
    element: <ClientList />,
    path: "/clients/list",
    action: "read",
    resource: "client",
  },
  {
    element: <ClientView />,
    path: "/clients/view/:id",
    action: "read",
    resource: "client",
  },
  {
    element: <ClientAdd />,
    path: "/clients/add/",
    action: "write",
    resource: "client",
  },
  {
    element: <ClientEdit />,
    path: "/clients/edit/:id",
    action: "write",
    resource: "client",
  },
  {
    element: <LeadList />,
    path: "/leads/list",
    action: "read",
    resource: "client",
  },
  {
    element: <LeadView />,
    path: "/leads/view/:id",
    action: "read",
    resource: "client",
  },
  {
    element: <ConverClient />,
    path: "/leads/convertclient/:id",
    action: "write",
    resource: "client",
  },
  {
    element: <LeadAdd />,
    path: "/leads/add/",
    action: "write",
    resource: "client",
  },
  {
    element: <LeadEdit />,
    path: "/leads/edit/:id",
    action: "write",
    resource: "client",
  },
  {
    element: <Import />,
    path: "/import/:module",
    action: "import",
    resource: "task",
  },
  {
    element: <ComingSoon />,
    path: "/coming-soon",
    resource: "coming-soon",
  },
  {
    element: <FileManager />,
    path: "/filemanager",
    resource: "filemanager",
  },
  {
    element: <FileManager />,
    path: "/filemanager/:pid",
    resource: "filemanager",
  },
  {
    element: <ServiceList />,
    path: "/services/list",
    action: "read",
    resource: "service",
  },
  {
    element: <ServiceView />,
    path: "/services/view/:id",
    action: "read",
    resource: "service",
  },
  {
    element: <ServiceAdd />,
    path: "/services/add/",
    action: "write",
    resource: "service",
  },
  {
    element: <ServiceEdit />,
    path: "/services/edit/:id",
    action: "write",
    resource: "service",
  },
  {
    element: <CategoryList />,
    path: "/category/list",
    action: "write",
    resource: "categories",
  },
  {
    element: <TaskList />,
    path: "/task/list",
    action: "read",
    resource: "task",
  },
  {
    element: <TaskSummary />,
    path: "/task/summarylist",
    action: "read",
    resource: "task",
  },
  {
    element: <TaskView />,
    path: "/task/view/:id",
    action: "read",
    resource: "task",
  },
  {
    element: <TaskWorkflow />,
    path: "/taskworkflow/view/:id",
    action: "read",
    resource: "task",
  },
  {
    element: <TaskAdd />,
    path: "/task/add/",
    action: "write",
    resource: "task",
  },
  {
    element: <TaskEdit />,
    path: "/task/edit/:id",
    action: "write",
    resource: "task",
  },
  {
    element: <DSCList />,
    path: "/digital-signature/list",
    action: "read",
    resource: "digital-signature",
  },
  {
    element: <DSCView />,
    path: "/digital-signature/view/:id",
    action: "read",
    resource: "digital-signature",
  },
  {
    element: <DSCAdd />,
    path: "/digital-signature/add",
    action: "write",
    resource: "digital-signature",
  },
  {
    element: <DSCEdit />,
    path: "/digital-signature/edit/:id",
    action: "write",
    resource: "digital-signature",
  },
  {
    element: <TeamList />,
    path: "/team/list",
    action: "read",
    resource: "team",
  },
  {
    element: <TeamView />,
    path: "/team/view/:id",
    action: "read",
    resource: "team",
  },
  {
    element: <Profile />,
    path: "/profile/view/:id",
    resource: "profile",
  },
  {
    element: <TeamEdit />,
    path: "/team/edit/:id",
    action: "write",
    resource: "team",
  },
  {
    element: <AttendanceList />,
    path: "/attendance/list",
    action: "read",
    resource: "attendance",
  },
  {
    element: <TimeSheetList />,
    path: "/timesheet/list",
    action: "read",
    resource: "time-sheet",
  },
  {
    element: <LeaveList />,
    path: "/leaves/list",
    action: "read",
    resource: "attendance",
  },
  {
    element: <EmployeeReimbursement />,
    path: "/employeereimbursement/list",
    action: "read",
    resource: "attendance",
  },
  {
    element: <TeamAdd />,
    path: "/team/add/",
    action: "write",
    resource: "team",
  },
  {
    element: <Organization />,
    path: "/organization/create",
    action: "write",
    resource: "profile",
    meta: {
      layout: "blank",
    },
  },
];

export default AppRoutes;
