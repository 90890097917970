import mock from '../mock'

const utilities = {
  status: [
    {
      name: 'Pending',
      id: 1
    },
    {
      name: 'Inprogress',
      id: 2
    },
    {
      name: 'Completed',
      id: 3
    },
    {
      name: 'On Hold',
      id: 4
    },
    {
      name: 'Cancelled',
      id: 5
    },
    {
      name: 'Sent for Review',
      id: 6
    },
    {
      name: 'Request Changes',
      id: 7
    },
    {
      name: 'To Do',
      id: 8
    },
    {
      name: 'Overue',
      id: 9
    }
  ]

}

mock.onPost('/api/tasks/status/dropdown').reply(200, utilities.status)
