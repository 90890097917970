// ** Icons Import
import { HiOutlineHome } from "react-icons/hi"

export default [
  {
    id: 'dashboards',
    title: 'Dashboard',
    icon: <HiOutlineHome />,
    action: 'read',
    resource: 'dashboard',
    navLink: '/dashboard'
  }
]
