// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as FileSaver from "file-saver"
import * as XLSX from "xlsx"
// ** Axios Imports
import axios from '@src/configs/axios/axiosConfig'
import { orgUserId } from '@src/helper/sassHelper'
import moment from 'moment'
const userId = orgUserId()

export const getData = createAsyncThunk('appInvoice/getData', async params => {
  const response = await axios.post('/taskinvoices/list', params)
  return {
    params,
    data: response.data.taskinvoices.taskinvoices,
    totalPages: response.data.taskinvoices.total
  }
})

const dateFormat = (value) => {
  if (value.length === 13) {
    return moment(value, 'x').format("MMM DD, YYYY")
  } else {
    return moment.unix(value).format("MMM DD, YYYY")
  }

}

export const exportInvoice = createAsyncThunk('appInvoice/exportInvoice', async params => {
  const response = await axios.post(`/taskinvoices/exportToExcel`, params)

  const paymentstatusObj = [
    { id: '5', name: "Pending" },
    { id: '6', name: "Partially Paid" },
    { id: '7', name: 'Paid' },
    { id: '11', name: 'Sent' }
  ]

  const result = await response.data.taskinvoices.map((obj) => {

    const data = {}
    const status = paymentstatusObj.find((item) => obj.paymentstatus === item.id)
    data['uniqueno'] = obj.uniqueno
    data['clientname'] = obj.contactname
    data['ivoiceitems'] = obj.taskinvoiceitemsservicename || ''
    data['enddate'] = dateFormat(obj.paymentdue)
    data['totalamount'] = obj.totalamount
    data['balance'] = obj.dueamount
    data['paymentstatus'] = status === undefined ? '' : status.name

    return data
  })

  const ws = await XLSX.utils.json_to_sheet(result, { origin: 'A2', skipHeader: true })
  const Heading = [['Invocie ID', 'Client', 'Invocie Items', 'Due Date', 'Total Amount', 'Balance', 'Payment Status']]
  XLSX.utils.sheet_add_aoa(ws, Heading)
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
  const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" })
  FileSaver.saveAs(data, 'invoice.xlsx')

})


export const addInvoice = createAsyncThunk('appInvoice/addInvoice', async (invoice, { rejectWithValue }) => {
  try {
    const response = await axios.post(`/taskinvoices/create`, invoice)
    return { invoices: response.data.taskinvoices }
  } catch (ex) {
    return rejectWithValue(getExceptionPayload(ex))
  }
})

export const addInvoiceItems = createAsyncThunk('appInvoice/addInvoiceItems', async (invoiceItems, { rejectWithValue }) => {
  try {
    const response = await axios.post(`/taskinvoiceitems/create`, { rows: invoiceItems })
    return { invoiceItems: response.data.taskinvoiceitems }
  } catch (ex) {
    return rejectWithValue(getExceptionPayload(ex))
  }
})

export const getInvoice = createAsyncThunk('appInvoice/getInvoice', async id => {
  const response = await axios.post('/taskinvoices/get', { id })
  return { invoice: response.data.taskinvoices }
})

export const getInvoiceItems = createAsyncThunk('appInvoice/getInvoiceItem', async invoiceId => {
  const response = await axios.post('/taskinvoiceitems/list', { invoiceId })
  return response.data.taskinvoiceitems
})

export const getInvoiceTaxes = createAsyncThunk('appInvoice/getInvoiceTax', async invoiceId => {
  const response = await axios.post('/invoicetaxes/list', { invoiceId })
  return response.data.invoicetaxes
})

export const getInvoiceItemTaxes = createAsyncThunk('appInvoice/getInvoiceItemTax', async invoiceId => {
  const response = await axios.post('/invoiceitemtaxes/list', { invoiceId })
  return response.data.invoiceitemtaxes
})

export const updateInvoice = createAsyncThunk('appInvoice/updateInvoice', async (invoice, { rejectWithValue }) => {
  try {
    const response = await axios.post(`/taskinvoices/update`, invoice)
    return { invoices: response.data.taskinvoices }
  } catch (ex) {
    return rejectWithValue(getExceptionPayload(ex))
  }
})

export const updateInvoiceItems = createAsyncThunk('appInvoice/updateInvoiceItems', async (invoiceItems, { rejectWithValue }) => {
  try {
    const response = await axios.post(`/taskinvoiceitems/update`, { rows: invoiceItems })
    return { invoiceItems: response.data.taskinvoiceitems }
  } catch (ex) {
    return rejectWithValue(getExceptionPayload(ex))
  }
})

export const getClient = createAsyncThunk('appInvoice/getClient', async id => {
  const response = await axios.post('/clients/get', { id })
  return response.data.clients
})

export const deleteInvoice = createAsyncThunk('appInvoice/deleteInvoice', async (id, { dispatch, getState }) => {
  await axios.post('/taskinvoices/delete', { id, updatedBy: userId })
  await dispatch(getData(getState().invoice.params))
  return id
})

export const deleteInvoiceBulk = createAsyncThunk('appInvoice/deleteInvoiceBulk', async (selectedIds, { dispatch, getState }) => {
  await axios.post('/taskinvoices/deletebulk', { selectedIds, updatedBy: userId })
  await dispatch(getData(getState().invoice.params))
  return id
})

export const deleteInvoiceItem = createAsyncThunk('appInvoice/deleteInvoiceItem', async (id, { }) => {
  await axios.post('/taskinvoiceitems/delete', { id, updatedBy: userId })
  return []
})

export const addInvoiceParticipant = createAsyncThunk('appInvoice/addInvoiceParticipant', async (data, { }) => {
  await axios.post('/taskinvoices/addparticipants', data)
  return []
})

export const resetInvoice = createAsyncThunk('appInvoice/resetInvoice', async (data, { }) => {
  return data
})

export const updateInvoiceParticipant = createAsyncThunk('appInvoice/updateInvoiceParticipant', async (data, { }) => {
  await axios.post('/taskinvoices/updateparticipants', data)
  return []
})

export const updateBulk = createAsyncThunk('appInvoice/updateBulk', async (data, { dispatch, getState }) => {
  await axios.post(`/taskinvoices/updateselectedrecords`, { ...data, updatedBy: userId })
  await dispatch(getData(getState().client.params))
  return ''
})

export const clientPortalCloneInvoice = createAsyncThunk('appChat/clientPortalCloneInvoice', async (obj, { }) => {
  await axios.post('/taskinvoices/clone', obj)
  return ''
})

export const invoiceByClient = createAsyncThunk('appChat/invoiceByClient', async (params, { }) => {

  const response = await axios.post('/taskinvoices/listbyclient', params)

  return {
    params,
    data: response.data.taskinvoices.taskinvoices,
    totalPages: response.data.taskinvoices.total
  }

})
export const applyFilter = createAsyncThunk('appSales/applyFilter', async (val, { }) => {
  
  return val
})

export const setActiveFilter = createAsyncThunk('appSales/setActiveFilter', async (val, { }) => {
  
  return val
})
export const appInvoiceSlice = createSlice({
  name: 'appInvoice',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    invoiceId: null,
    invoiceItems: [],
    selectedInvoice: null,
    filter : null,
    activefilter: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
      state.params = action.payload.params
      state.invoiceId = null
    })
    builder.addCase(addInvoice.fulfilled, (state, action) => {
      state.invoiceId = action.payload.invoices.id
    })
    builder.addCase(updateInvoice.fulfilled, (state, action) => {
      state.invoiceId = action.payload.invoices.id
    })
    builder.addCase(addInvoiceItems.fulfilled, (state, action) => {
      state.invoiceItems = action.payload.invoiceItems
    })
    builder.addCase(updateInvoiceItems.fulfilled, (state, action) => {
      state.invoiceItems = action.payload.invoiceItems
    })
    builder.addCase(resetInvoice.fulfilled, (state, action) => {
      state.invoiceId = action.payload
    })
    builder.addCase(invoiceByClient.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.total = action.payload.totalPages
      state.params = action.payload.params
    })
    builder.addCase(getInvoice.fulfilled, (state, action) => {
      state.selectedInvoice = action.payload.invoice
      state.invoiceId = null
    })
    .addCase(applyFilter.fulfilled, (state, action) => {
      state.filter = action.payload
    })
    .addCase(setActiveFilter.fulfilled, (state, action) => {
      state.activefilter = action.payload
    }) 
  }
})

export default appInvoiceSlice.reducer
