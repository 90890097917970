import mock from '../mock'

const utilities = {
  status: [
    {
      name: 'Active',
      id: 1,
      color: 'primary'
    },
    {
      name: 'In Active',
      id: 2,
      color: 'warning'
    },
    {
      name: 'Expired',
      id: 2,
      color: 'danger'
    }
  ],
  location: [
    {
      name: 'Office',
      id: true,
      color: 'primary'
    },
    {
      name: 'With Client',
      id: false,
      color: 'success'
    }
  ],
  timePeriodFilter : [
    {
      id: 0,
      name : 'Select All'
    },
    {
      id: 1,
      name : '7 Days'
    },
    {
      id: 2,
      name : '30 Days'
    }
  ]
}


mock.onPost('/api/dsc/status/dropdown').reply(200, utilities.status)

mock.onPost('/api/dsc/timePeriodFilter/dropdown').reply(200, utilities.timePeriodFilter)

mock.onPost('/api/dsc/location/dropdown').reply(200, utilities.location)

