// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const setNotification = createAsyncThunk('appNotifications/setNotification', async data => {
  return data
})

export const notificationSlice = createSlice({
  name: 'appNotifications',
  initialState: {
    message: null,
    notificationFlag : false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(setNotification.fulfilled, (state, action) => {
        state.message = action.payload.message
      })

  }
})

export default notificationSlice.reducer
