import mock from '../mock'

const utilities = {

  paymentStatus : [
    {name : 'Paid', id:7 },
    {name : 'Partially Paid', id: 6},
    {name : 'Pending', id:5 }

  ]
}

mock.onPost('/api/sales/status/dropdown').reply(200, utilities.paymentStatus)
