import { RefreshCw } from 'react-feather'

export default [
  {
    id: 'billingorg',
    title: 'Billing Organization',
    icon: <RefreshCw />,
    action: 'read',
    resource: 'setting',
    navLink: '/setting/billingorganization'
  }
]