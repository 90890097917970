// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as FileSaver from "file-saver"
import * as XLSX from "xlsx"
// ** Axios Imports
import axios from '@src/configs/axios/axiosConfig'
import moment from 'moment'
const statsuArr = ['', 'Applied', 'Approved', 'Rejected']
const paidstatsuArr = ['', 'Not Paid', 'paid']
export const reimbursementList = createAsyncThunk('appUsers/reimbursementList', async (params, { }) => {
  const response = await axios.post('/employeesreimbursements/list', params)
  return {
    params,
    data: response.data.employeereimbursement.employeereimbursement,
    totalPages: response.data.employeereimbursement.total
  }
})

export const applyReimbursement = createAsyncThunk('appUsers/applyReimbursement', async (data, { dispatch, rejectWithValue }) => {
  try {
    await axios.post('/employeesreimbursements/create', data)
    const obj = {
      q: "",
      page: 1,
      perPage: 6,
      status: 'All',
      userId: data.userId
    }
    await dispatch(reimbursementList(obj))
    return id
  } catch (ex) {
    return rejectWithValue(ex.response)
  }

})

export const UpdateReimbursement = createAsyncThunk('appUsers/UpdateReimbursement', async (data, { dispatch, rejectWithValue }) => {
  try {
    await axios.post('/employeesreimbursements/update', data)
    const obj = {
      q: "",
      page: 1,
      perPage: 6,
      status: 'All',
      userId: data.userId
    }
    await dispatch(reimbursementList(obj))
    return id
  } catch (ex) {
    return rejectWithValue(ex.response)
  }

})

export const exportReimbursement = createAsyncThunk('appUsers/exportReimbursement', async params => {
  const response = await axios.post(`/employeesreimbursements/exportToExcel`, params)

  const result = await response.data.employeereimbursement.map((obj) => {

    const data = {}
    data['applicant'] = obj.name
    data['reason'] = obj.reason
    data['date'] = moment(obj.date, 'x').format('MMM DD, Y')
    data['amount'] = obj.amount
    data['status'] = statsuArr[obj.status]
    data['paid'] = paidstatsuArr[obj.paymentstatus]
    return data
  })

  const ws = await XLSX.utils.json_to_sheet(result, { origin: 'A2', skipHeader: true })
  const Heading = [['Applicant', 'Reason', 'Date', 'Amount', 'Status', 'Paid']]
  XLSX.utils.sheet_add_aoa(ws, Heading)
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
  const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" })
  FileSaver.saveAs(data, 'Employee_Reimbursement.xlsx')

})

export const deleteReimbursement = createAsyncThunk('appUsers/deleteReimbursement', async (data, { dispatch }) => {
  await axios.post('/employeesreimbursements/delete', { id: data?.id, updatedBy: userId })
  const obj = {
    q: "",
    page: 1,
    perPage: 6,
    status: 'All',
    userId: data?.userId
  }
  await dispatch(reimbursementList(obj))
  return id
})

export const appEmployeeLeaveSlice = createSlice({
  name: 'appEmployeeLeave',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    employeeReimbursements: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(reimbursementList.fulfilled, (state, action) => {
        state.employeeReimbursements = action.payload.data
        state.total = action.payload.totalPages
        state.params = action.payload.params
      })
  }
})

export default appEmployeeLeaveSlice.reducer
